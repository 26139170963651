import React, { useState } from "react";
import Styles from "./SignUp.module.scss";
import { countryList } from "../../utility/countryList";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createClientAccount, getEmailOTP } from "../../services/api.service";
import { showToast } from "../../redux/toast/ToastAction";
import logo from '../../asset/images/synchrowork-logo.svg';

function SignUp() {
  const [inputData, setInputData] = useState({
    name: "",
    email: "",
    phone: "",
    organizationName: "",
    country: "India",
  });
  // redux dispatch
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // CREATE ACCOUNT
  const createAccount = async () => {
    try {
      const { data } = await createClientAccount(inputData);
      dispatch({
        type: "GET_EMAIL_OTP",
        payload: inputData.email,
      });

      if (data?.success) {
        const formData = {
          email: inputData.email,
        };
        const resp = await getEmailOTP(formData);
        if (resp?.data?.success) {
          navigate("/otp");
        }
      } else {
        dispatch(showToast(data?.message));
      }
    } catch (e) {
      dispatch(showToast(e.message));
    }
  };

  const validateForm = async () => {
    try {
      const { name, email, country } = inputData;
      let error = false;

      if (
        name.trim() === "" ||
        name === undefined ||
        country.trim() === "" ||
        country === undefined
      ) {
        dispatch(showToast("Kindly fill up the asteriks (*) field"));
        error = true;
      } else if (
        !email
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
      ) {
        dispatch(showToast("Invalid Email Address"));
        error = true;
      }

      if (!error) createAccount();
    } catch (e) {
      dispatch(showToast(e.message));
    }
  };
  return (
    <div className={Styles.Signup_Container}>
      <div className={Styles.Form}>
        <div className={Styles.Header}>
          <h3>Let's get started</h3>
          <h4>Just a step away from tracking your projects.</h4>
        </div>
        <label>
          Full Name *
          <input
            type="text"
            value={inputData.name}
            onChange={(e) =>
              setInputData({ ...inputData, name: e.target.value })
            }
            placeholder="eg. John Doe"
          />
        </label>

        <label>
          Email ID *
          <input
            type="email"
            value={inputData.email}
            onChange={(e) =>
              setInputData({ ...inputData, email: e.target.value })
            }
            placeholder="eg. john.doe@company.com"
          />
        </label>

        <label>
          Organization Name
          <input
            type="text"
            value={inputData.organizationName}
            onChange={(e) =>
              setInputData({ ...inputData, organizationName: e.target.value })
            }
            placeholder="eg. Delta Corp Pvt Ltd"
          />
        </label>

        <label>
          Country *
          <select
            value={inputData.country}
            onChange={(e) =>
              setInputData({ ...inputData, country: e.target.value })
            }
          >
            {countryList?.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
        </label>

        <div className={Styles.Footer}>
          <button onClick={validateForm}>CREATE ACCOUNT</button>

          <p>
            Already have an account? <Link to="/">Sign In</Link>
          </p>

          <img src={logo} alt='synchrowork logo' className={Styles.logo}/>
        </div>
      </div>
    </div>
  );
}

export default SignUp;

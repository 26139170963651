/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Styles from "./Profile.module.scss";
import { BG1, BG2, NO_AGENCY } from "../../asset";
import Header from "../../components/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfileDetails } from "../../redux/auth/AuthAction";
import { updateClientProfile } from "../../services/api.service";
import { showToast } from "../../redux/toast/ToastAction";
import { useNavigate } from "react-router-dom";
import { fetchAgencyProfile } from "../../redux/agency/AgencyAction";
import { countryList } from "../../utility/countryList";

function Profile() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // dispatch
  const dispatch = useDispatch();

  // selector
  const profile = useSelector((state) => state?.Profile);
  const profileData = useSelector((state) => state?.Auth?.profile);
  const { email, otp } = useSelector((state) => state?.Login);
  const { selectedAgencyProfile } = useSelector((state) => state?.Agency);

  // fetch profile
  const fetchProfile = () => {
    const formData = {
      email,
      otp,
    };
    dispatch(fetchProfileDetails(formData));
    setLoading(false);
  };

  // useEffect
  useEffect(() => {
    // console.log("======SELECTED DATA=====", selectedAgencyProfile);
    fetchProfile();
  }, [email, otp]);

  // UPDATE SELECTED AGENCY
  const updateSelectedAgency = async (selectedAgency) => {
    const formData = {
      _id: profileData?._id,
      updatedData: {
        selectedAgency,
      },
    };
    try {
      setLoading(true);
      const { data } = await updateClientProfile(formData);
      if (data?.status) {
        setLoading(false);
        dispatch(showToast("Current Agency Updated"));
        dispatch({
          type: "PROFILE",
          payload: {
            name: "selectedAgency",
            value: selectedAgency,
          },
        });
        navigate(-1);
      }
    } catch (e) {
      setLoading(false);
      dispatch(showToast("Something went wrong, Please try again later."));
    }
  };

  // PROFILE UPDATE
  const updateProfile = async () => {
    const formData = {
      _id: profileData?._id,
      updatedData: {
        name: profile.fullName,
        email: profile.email,
        organizationName: profile.organization,
        country: profile.country,
        address: profile.address,
        gstin: profile.gstin,
        panNumber: profile.panNumber,
        gender: profile.gender,
      },
    };
    try {
      setLoading(true);
      const { data } = await updateClientProfile(formData);
      if (data?.status) {
        setLoading(false);
        dispatch(showToast("Profile Updated"));
        navigate(-1);
      }
    } catch (e) {
      setLoading(false);
      dispatch(showToast("Something went wrong, try again later"));
    }
  };

  // LOGOUT
  const logout = async () => {
    try {
      localStorage.removeItem("isLogIn");
      localStorage.removeItem("phone");
      localStorage.removeItem("email");
      localStorage.removeItem("token");

      dispatch({
        type: "LOG-OUT",
      });
    } catch (e) {
      dispatch(showToast(e?.message));
    }
  };
  return (
    <>
      <Header title="Your Profile" />
      <div className={Styles.Profile_Container}>

        <div className={Styles.Agency_Container}>
          {profileData?.agency?.length === 0 && (
            <div className={Styles.NoAgency}>
              <img src={NO_AGENCY} alt="No agency" />
              <h2>No Agency Found !</h2>
              <h3>Agencies working with you will appear here</h3>
            </div>
          )}
          <h4>Selected Agency</h4>
          {profileData?.agency?.length > 0
            ? profileData?.agency?.map((item, index) => (
              <button
                onClick={() => {
                  dispatch({
                    type: "PROFILE",
                    payload: {
                      name: "agency",
                      value: item?.name,
                    },
                  });
                  updateSelectedAgency(item?.name);
                  dispatch(fetchAgencyProfile(item?._id));
                }}
              >
                {profile?.agency === item?.name ? (
                  <span style={{backgroundColor: '#8048f4'}}>
                    <i className="fa-solid fa-check"></i>
                  </span>
                ) : (
                  <span style={{backgroundColor: '#E4E4E4'}}>
                    <i className="fa fa-check"></i>
                  </span>
                )}
                <p>{item?.name}</p>
              </button>
            ))
            : null}
        </div>

        <div className={Styles.Profile_Container}>
          <h4>Your Details</h4>

          <label>
            Full Name
            <input
              type="text"
              placeholder="Full Name"
              value={profile?.fullName}
              onChange={(e) =>
                dispatch({
                  type: "PROFILE",
                  payload: {
                    name: "fullName",
                    value: e.target.value,
                  },
                })
              }
            />
          </label>

          <label>
            Gender
            <select
              value={profile.gender}
              onChange={(e) => {
                dispatch({
                  type: "PROFILE",
                  payload: {
                    name: "gender",
                    value: e.target.value,
                  },
                });
              }}
            >
              <option value="male">Male</option>

              <option value="female">Female</option>
            </select>
          </label>

          <label>
            Email
            <input
              type="email"
              placeholder="Email"
              value={profile?.email}
              onChange={(e) => {
                dispatch({
                  type: "PROFILE",
                  payload: {
                    name: "email",
                    value: e.target.value,
                  },
                });
              }}
            />
          </label>

          <label>
            Organization
            <input
              type="text"
              placeholder="Organization"
              value={profile?.organization}
              onChange={(e) =>
                dispatch({
                  type: "PROFILE",
                  payload: {
                    name: "organization",
                    value: e.target.value,
                  },
                })
              }
            />
          </label>

          <label>
            Country
            <select
              value={profile?.country}
              onChange={(e) =>
                dispatch({
                  type: "PROFILE",
                  payload: {
                    name: "country",
                    value: e.target.value,
                  },
                })
              }
            >
              {countryList?.map((item, index) => (
                <option value={item} key={index}>
                  {item}
                </option>
              ))}
            </select>
          </label>

          <label>
            Address
            <input
              type="text"
              placeholder="Address"
              value={profile?.address}
              onChange={(e) => {
                dispatch({
                  type: "PROFILE",
                  payload: {
                    name: "address",
                    value: e.target.value,
                  },
                });
              }}
            />
          </label>

          <label>
            GSTIN (Optional)
            <input
              type="text"
              placeholder="GSTIN"
              value={profile?.gstin}
              onChange={(e) => {
                dispatch({
                  type: "PROFILE",
                  payload: {
                    name: "gstin",
                    value: e.target.value,
                  },
                });
              }}
            />
          </label>

          <label>
            PAN Number (Optional)
            <input
              type="text"
              placeholder="PAN Number"
              value={profile?.panNumber}
              onChange={(e) => {
                dispatch({
                  type: "PROFILE",
                  payload: {
                    name: "panNumber",
                    value: e.target.value,
                  },
                });
              }}
            />
          </label>

          <button onClick={updateProfile}>UPDATE</button>
        </div>

        <div className={Styles.Logout_Container}>
          <button className={Styles.LogOut} onClick={logout}>
            LOG OUT
          </button>
        </div>
      </div>
    </>
  );
}

export default Profile;

export const showToast = (message) => (dispatch) => {
  dispatch({
    type: "SHOW_TOAST",
    payload: message,
  });
  // setTimeout(() => {
  // dispatch({
  //   type: "HIDE_TOAST",
  // });
  // }, 2000);
};

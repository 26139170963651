import React from "react";
import Styles from "./Header.module.scss";
import { BG1, BG2 } from "../../asset";
import { useNavigate } from "react-router-dom";

function Header({ title }) {
  const navigate = useNavigate();
  return (
    <div className={Styles.Header_Container}>
      <button onClick={() => navigate(-1)}>
        <i className="fa-solid fa-arrow-left"></i>
      </button>
      <h3>{title}</h3>
{/* 
      <img className={Styles.Bg1} src={BG1} alt="bg1" />
      <img className={Styles.Bg2} src={BG2} alt="bg1" /> */}
    </div>
  );
}

export default Header;

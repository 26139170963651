/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import Styles from "./Otp.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getEmailOTP, verifyEmailOTP } from "../../services/api.service";
import { showToast } from "../../redux/toast/ToastAction";

function Otp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const email = useSelector((state) => state.Login.email);
  const [pin1, setPin1] = useState("");
  const [pin2, setPin2] = useState("");
  const [pin3, setPin3] = useState("");
  const [pin4, setPin4] = useState("");
  const pin1ref = useRef(null);
  const pin2ref = useRef(null);
  const pin3ref = useRef(null);
  const pin4ref = useRef(null);
  const submitOtpRef = useRef(null);

  useEffect(() => {
    if (!email) {
      navigate(-1);
    }
    pin1ref.current.focus();
  }, []);

  const verifyOTP = async () => {
    const formData = {
      email,
      otp: `${pin1}${pin2}${pin3}${pin4}`,
    };

    try {
      const { data } = await verifyEmailOTP(formData);
      console.log(data);
      if (data?.res?.type === "error") {
        dispatch(showToast(data?.message));
      } else {
        localStorage.setItem("isLogIn", String(true));
        localStorage.setItem("email", JSON.stringify(formData));
        localStorage.setItem("phone", JSON.stringify(formData));
        dispatch({ type: "LOG-IN" });
        dispatch({ type: "SET_OTP", payload: `${pin1}${pin2}${pin3}${pin4}` });
      }
    } catch (e) {
      dispatch(showToast(e.message));
    }
  };

  const resendOTPagain = async () => {
    const formData = {
      email,
    };
    dispatch({
      type: "GET_EMAIL_OTP",
      payload: email,
    });
    try {
      const { data } = await getEmailOTP(formData);
      if (data?.success) {
        dispatch(showToast("OTP Sent Successfully"));
      } else {
        dispatch(showToast("OTP Expired, Login again."));
        navigate("/");
      }
    } catch (e) {
      dispatch(showToast(e.message));
    }
  };
  return (
    <div className={Styles.Otp_Container}>
      <div className={Styles.Top}>
        <button
          onClick={() => {
            navigate("/");
          }}
        >
          <i className="fa-solid fa-arrow-left"></i>
        </button>

        <div>
          <h3>Verify Details</h3>
          <h4>OTP sent to {email}</h4>
        </div>
      </div>
      <div className={Styles.Bottom}>
        <div className={Styles.Input}>
          <h6>Enter OTP</h6>

          <div className={Styles.Form}>
            <input
              type="number"
              ref={pin1ref}
              maxLength={1}
              value={pin1}
              onChange={(e) => {
                if (e.target.value.trim().length === 1) {
                  pin2ref.current.focus();
                }
                setPin1(e.target.value.trim());
              }}
            />
            <input
              type="number"
              ref={pin2ref}
              maxLength={1}
              value={pin2}
              onChange={(e) => {
                if (e.target.value.trim().length === 1) {
                  pin3ref.current.focus();
                } else {
                  pin1ref.current.focus();
                }
                setPin2(e.target.value.trim());
              }}
            />
            <input
              type="number"
              ref={pin3ref}
              maxLength={1}
              value={pin3}
              onChange={(e) => {
                if (e.target.value.trim().length === 1) {
                  pin4ref.current.focus();
                } else {
                  pin2ref.current.focus();
                }
                setPin3(e.target.value.trim());
              }}
            />
            <input
              type="number"
              ref={pin4ref}
              maxLength={1}
              value={pin4}
              onChange={(e) => {
                if (e.target.value.trim().length === 1) {
                  submitOtpRef.current.focus();
                } else {
                  pin3ref.current.focus();
                }
                setPin4(e.target.value.trim());
              }}
            />
          </div>

          <h5>
            Didn't receive OTP?
            <span onClick={resendOTPagain}>Resend OTP</span>
          </h5>
        </div>

        <button
          ref={submitOtpRef}
          onClick={verifyOTP}
          disabled={!pin1 || !pin2 || !pin3 || !pin4}
          style={{
            backgroundColor:
              !pin1 || !pin2 || !pin3 || !pin4 ? "grey" : "#8048f4",
          }}
        >
          Confirm OTP
        </button>
      </div>
    </div>
  );
}

export default Otp;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfileDetails } from "../../redux/auth/AuthAction";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import SignIn from "../../pages/SignIn/SignIn";
import SignUp from "../../pages/SignUp/SignUp";
import Otp from "../../pages/Otp/Otp";
import Home from "../../pages/Home/Home";
import Profile from "../../pages/Profile/Profile";
import ProjectDetails from "../../pages/ProjectDetails/ProjectDetails";
import Invoice from "../../pages/Invoice/Invoice";
import CompletedProject from "../../pages/CompletedProject/CompletedProject";

function Navigation() {
  const isLogIn = useSelector((state) => state.Auth.isLoggedIn);
  // dispatch
  const dispatch = useDispatch();

  const loadFromLocalStorage = () => {
    try {
      const isStorageLogIn = localStorage.getItem("isLogIn");
      if (isStorageLogIn) {
        dispatch({ type: "LOG-IN" });
      }
      const phone = localStorage.getItem("phone");
      if (phone) {
        const phoneAndOTP = JSON.parse(phone);

        const formData = {
          phone: phoneAndOTP?.phNumber,
          otp: phoneAndOTP?.otp,
        };
        dispatch(fetchProfileDetails(formData));
      }
    } catch (e) {}
  };
  useEffect(() => {
    loadFromLocalStorage();
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        {isLogIn ? (
          <>
            <Route path="/" element={<Home />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/completed-projects" element={<CompletedProject />} />
            <Route path="/project/:id" element={<ProjectDetails />} />
            <Route path="/invoice/:id" element={<Invoice />} />
            <Route path="/*" element={<Navigate to="/" />} />
          </>
        ) : (
          <>
            <Route path="/" element={<SignIn />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/otp" element={<Otp />} />
            <Route path="/*" element={<Navigate to="/" />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
}

export default Navigation;

const initState = {
  phoneNumber: "",
  email: "",
  otp: "",
};

const LoginReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_OTP":
      return {
        ...state,
        phoneNumber: action.payload,
      };
    case "GET_EMAIL_OTP":
      return {
        ...state,
        email: action.payload,
      };
    case "SET_OTP":
      return {
        ...state,
        otp: action.payload,
      };
    default:
      return state;
  }
};

export default LoginReducer;

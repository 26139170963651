import { callBackend } from "./http.service";

// GET OTP
export const getOTP = async (data) => {
  return await callBackend("POST", "sms/msg91/send-otp", data);
};

export const getEmailOTP = async (data) => {
  return await callBackend("POST", "mail/sendEmailOTP", data);
};

// RESEND OTP
export const resendOTP = async (data) => {
  return await callBackend("POST", "sms/msg91/resend-otp", data);
};

// VERIFY OTP
export const veriftOTP = async (data) => {
  return await callBackend("POST", "sms/msg91/verify-otp", data);
};

export const verifyEmailOTP = async (data) => {
  return await callBackend("POST", "mail/verifyEmailOTP", data);
};

// GET PROFILE DETRAILS
export const getProfile = async (data) => {
  // console.log(data)
  // return await callBackend("POST", "client/signinWithOTP", data);
  return await callBackend("POST", "client/signinWithEmail", data);
};

export const getProfileByEmailId = async (email) => {
  return await callBackend("GET", `client/getClientProfileByEmail/${email}`);
};

// GET PROJECT DETAILS BY ID
export const getProjectById = async (id) => {
  return await callBackend("GET", `project/getProjectDetailsByID/${id}`);
};

// CREATE PROFILE
export const createClientAccount = async (FormData) => {
  return await callBackend("POST", "client/createClientProfile", FormData);
};

// UPDATE PROFILE
export const updateClientProfile = async (FormData) => {
  return await callBackend("PUT", "client/updateClientProfile", FormData);
};

// SAVE DEVICE TOKEN FOR NOTIFICATION
export const saveDeviceToken = async (formData) => {
  // console.log(formData);
  return await callBackend("POST", "fcm/saveToken", formData);
};

export const getAgencyProfile = async (id) => {
  return await callBackend("GET", `agency/getAgencyProfileById/${id}`);
};

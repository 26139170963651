const initialState = {
  showToast: false,
  toastMessage: "",
};

const ToastReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SHOW_TOAST":
      return {
        ...state,
        showToast: true,
        toastMessage: action.payload,
      };
    case "HIDE_TOAST":
      return {
        ...state,
        showToast: false,
        toastMessage: "",
      };
    default:
      return state;
  }
};

export default ToastReducer;

import React from "react";
import Styles from "./Toast.module.scss";
import { useDispatch, useSelector } from "react-redux";

function Toast() {
  const { toastMessage, showToast } = useSelector((state) => state.Toast);
  const dispatch = useDispatch();

  return (
    <>
      {showToast && (
        <div className={Styles.Toast_Container}>
          <div className={Styles.Toast_Box}>
            <p>{toastMessage}</p>
            <button
              onClick={() => {
                dispatch({
                  type: "HIDE_TOAST",
                });
              }}
            >
              OK
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default Toast;

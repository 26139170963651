/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getProjectById } from "../../services/api.service";
import { showToast } from "../../redux/toast/ToastAction";
import { CANCELLED, HOLD, STATUS, TIMER } from "../../asset";
import Styles from "./ProjectDetails.module.scss";
import Header from "../../components/Header/Header";

function ProjectDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // STATE
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const { profile } = useSelector((state) => state.Auth);
  const [refreshing, setRefreshing] = React.useState(false);

  // SELECTOR
  const { selectedAgencyProfile } = useSelector((state) => state.Agency);

  // getting details of project
  const fetchProjectDetails = async () => {
    try {
      setLoading(true);

      const resp = await getProjectById(id);
      // console.log("===== PD =====", resp?.data?.result);
      if (resp?.data?.success) {
        setLoading(false);
        setData(resp?.data?.result);
      } else {
        dispatch(showToast(resp?.data));
      }
    } catch (e) {
      dispatch(showToast(e.message));
    }
  };

  // REFRESH
  const wait = (timeout) => {
    return new Promise((resolve) => setTimeout(resolve, timeout));
  };

  // USE EFFECT
  useEffect(() => {
    fetchProjectDetails();
  }, [id]);

  const statusIcon = {
    "In Progress": (
      <img src={TIMER} alt="In Progress" style={{ width: 16, height: 16 }} />
    ),
    Completed: (
      <img src={STATUS} alt="Completed" style={{ width: 16, height: 16 }} />
    ),
    Hold: <img src={HOLD} alt="Hold" style={{ width: 16, height: 16 }} />,
    Cancelled: (
      <img src={CANCELLED} alt="Cancelled" style={{ width: 16, height: 16 }} />
    ),
  };

  const payUsingUPI = (amt) => {
    if (selectedAgencyProfile?.paymentID) {
      let url = `upi://pay?pa=${selectedAgencyProfile?.paymentID}&pn=${selectedAgencyProfile?.name}&cu=INR&am=${amt}`;
      window.open(url, "_blank");
    } else {
      dispatch(
        showToast(
          "To use direct pay option, please ask your freelancer/agency to add UPI address in their profile."
        )
      );
    }
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return (
    <div className={Styles.ProjectDetails_Container}>
      <Header title={data?.name} />

      <div className={Styles.ProjectContainer}>
        <div className={Styles.Project_Details}>
          {loading ? (
            <h1 className={Styles.Loader}>
              <i className="fa fa-spinner fa-spin" />
            </h1>
          ) : (
            <>
              <div className={Styles.Item}>
                <h4>Project Type</h4>
                <div className={Styles.Type_Container}>
                  {data?.type?.map((item, index) => (
                    <p key={index} className={Styles.Type}>
                      {item}
                    </p>
                  ))}
                </div>
              </div>
              {data?.resources?.length > 0 && (
                <div className={Styles.Item}>
                  <h4>Project Resources</h4>
                  <div className={Styles.Resource_Container}>
                    {data?.resources?.map((item, index) => (
                      <p key={index} className={Styles.Type}>
                        {item?.name}
                      </p>
                    ))}
                  </div>
                </div>
              )}
              <div className={Styles.Item}>
                <h4>Project Status</h4>
                <h5>
                  {statusIcon[data?.status]}
                  {data?.status}
                </h5>
              </div>
              <div className={Styles.Item}>
                <h4>
                  {data?.agency?.businessType
                    ? capitalizeFirstLetter(data?.agency?.businessType)
                    : "Agency"}
                </h4>
                <h5>{data?.agency?.name}</h5>
              </div>

              <div className={Styles.Item}>
                <h4>Budget</h4>
                <h5>
                  INR{" "}
                  {data?.budget?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </h5>
              </div>

              <div className={Styles.Item}>
                <h4>Checklist</h4>
                {data?.projectSteps?.map((item, index) => (
                  <p className={Styles.CheckList}>
                    {item?.status ? (
                      <span className={Styles.Check}>
                        <i className="fa fa-check-circle" />
                      </span>
                    ) : (
                      <span className={Styles.Uncheck}>
                        <i className="fa fa-check-circle" />
                      </span>
                    )}
                    {item?.title}
                  </p>
                ))}
              </div>
            </>
          )}
        </div>
        {!data?.invoices?.length && (
          <div className={Styles.Invoice_Container}>
            <h2>
              <span>
                <i className="fa fa-file-invoice-dollar" />
              </span>
              Invoices appear here
            </h2>
          </div>
        )}
        {/* <div className={Styles.Bar}></div> */}
        <div className={Styles.Invoice_Container_Pending}>
          {data?.invoices?.length > 0 &&
            data?.invoices
              ?.filter((item) => item?.status === "Pending")
              ?.map((item, index) => (
                <div className={Styles.Invoice_Pending}>
                  <div className={Styles.Left}>
                    <h4> Invoice #{item?.invoiceNumber}</h4>
                    <h5>
                      Rs.{item?.subTotal} due on{" "}
                      {new Date(item?.dueDate).toDateString()}
                    </h5>
                    {/* <h5>due on {new Date(item?.dueDate).toDateString()}</h5> */}
                  </div>
                  <div className={Styles.Right}>

                    <button>
                      <a target="_blank" href={`https://agency.synchrowork.com/invoice/${item?._id}`}>
                        View Invoice
                      </a>
                    </button>

                    <button onClick={()=>payUsingUPI(item?.subTotal)}>Pay {item?.subTotal}</button>
                  </div>
                </div>
              ))}
        </div>
        {/* <div className={Styles.Bar}></div> */}
        <div className={Styles.Invoice_Container_Paid}>
          {data?.invoices?.length > 0 &&
            data?.invoices
              ?.filter((item) => item?.status === "Paid")
              ?.map((item, index) => (
                <div className={Styles.Invoice_Paid}>
                  <div className={Styles.Left}>
                    <h4> Invoice #{item?.invoiceNumber}</h4>

                    <h5>
                      <span>
                        <i className="fa fa-check-circle" />
                      </span>
                      Payment Completed
                    </h5>
                  </div>
                  <div className={Styles.Right}>
                    <button
                      onClick={() => {
                        navigate(`/invoice/${item?._id}`);
                      }}
                    >
                      View Invoice
                    </button>
                  </div>
                </div>
              ))}
        </div>
      </div>

    </div>
  );
}

export default ProjectDetails;

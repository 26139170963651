/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Styles from "./SignIn.module.scss";
import { IMAGE1, IMAGE2, IMAGE3 } from "../../asset";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getEmailOTP } from "../../services/api.service";
import { showToast } from "../../redux/toast/ToastAction";
import logo from '../../asset/images/synchrowork-logo.svg';

function SignIn() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeCarousel, setActiveCarousel] = useState(0);
  const [email, setEmail] = React.useState("");

  const triggerEmailOTP = async () => {
    // console.log("EMAIL OTP TRIGGERED");
    if (
      !email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      return dispatch(showToast("Invalid Email Address"));
    }
    const formData = {
      email,
    };
    dispatch({
      type: "GET_EMAIL_OTP",
      payload: email,
    });
    try {
      const { data } = await getEmailOTP(formData);
      // console.log(data);
      if (data?.success) {
        navigate("/otp");
      } else {
        showToast(data.message);
        navigate("/sign-up");
      }
    } catch (e) {
      showToast(e.message);
    }
  };

  const CAROUSEL = [
    {
      id: 1,
      title: "Easy Project Tracking",
      body: "SynchroWork allows you to track your ongoing projects,invoices and status dynamically",
      image: IMAGE1,
    },
    {
      id: 2,
      title: "Multiple Agencies",
      body: "Single application to sync with multiple creative agencies registered with us",
      image: IMAGE2,
    },
    {
      id: 3,
      title: "Secured Payment",
      body: "Send out payments quick and securly to the agencies directly",
      image: IMAGE3,
    },
  ];

  const changeCarouselAutomatically = () => {
    setActiveCarousel((prev) => {
      if (prev === 2) {
        return 0;
      } else {
        return prev + 1;
      }
    });
  };

  useEffect(() => {
    dispatch({
      type: "GET_EMAIL_OTP",
      payload: "",
    });
    const interval = setInterval(() => {
      changeCarouselAutomatically();
    }, 2500);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={Styles.SignIn_Container}>
      <div className={Styles.Carousel_Container}>
        <img src={CAROUSEL[activeCarousel].image} alt="carousel" />
        <h3>{CAROUSEL[activeCarousel].title}</h3>
        <div className={Styles.Count_Controller}>
          {CAROUSEL.map((item) => (
            <div
              className={
                activeCarousel === item.id - 1 ? Styles.Active : Styles.Inactive
              }
            ></div>
          ))}
        </div>
      </div>
      <div className={Styles.Form_Container}>
        <input
          type="email"
          placeholder="Enter your registered email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button
          onClick={triggerEmailOTP}
          style={{
            backgroundColor: !email.trim() ? "grey" : "#8048f4",
          }}
          disabled={!email.trim()}
        >
          Get OTP
        </button>

        <p>Don't have an account yet ?</p>

        <Link to="/sign-up">Sign Up</Link>

        <img src={logo} alt='synchrowork logo' className={Styles.logo}/>
      </div>
    </div>
  );
}

export default SignIn;

import React from "react";
import Navigation from "./components/Navigation.js/Navigation";
import Toast from "./components/Toast/Toast";

function App() {
  return (
    <>
      <Navigation />
      <Toast />
    </>
  );
}

export default App;

import { getProfile } from "../../services/api.service";
// import { showToast, somethingWentWrong } from "../../util/Toast";

export const fetchProfileDetails = (Formdata) => async (dispatch) => {
  try {
    const { data } = await getProfile(Formdata);
    if (data?.success) {
      localStorage.setItem("token", data?.token);
      dispatch({
        type: "GET-PROFILE",
        payload: {
          token: data?.token,
          profile: data?.profile,
        },
      });
      dispatch({
        type: "PROFILE",
        payload: {
          name: "loading",
          value: false,
        },
      });
      dispatch({
        type: "PROFILE",
        payload: {
          name: "fullName",
          value: data?.profile?.name,
        },
      });
      dispatch({
        type: "PROFILE",
        payload: {
          name: "email",
          value: data?.profile?.email,
        },
      });
      dispatch({
        type: "PROFILE",
        payload: {
          name: "organization",
          value: data?.profile?.organizationName,
        },
      });
      dispatch({
        type: "PROFILE",
        payload: {
          name: "agency",
          value: data?.profile?.selectedAgency,
        },
      });
      dispatch({
        type: "PROFILE",
        payload: {
          name: "country",
          value: data?.profile?.country,
        },
      });
      dispatch({
        type: "PROFILE",
        payload: {
          name: "address",
          value: data?.profile?.address,
        },
      });
      dispatch({
        type: "PROFILE",
        payload: {
          name: "panNumber",
          value: data?.profile?.panNumber,
        },
      });
      dispatch({
        type: "PROFILE",
        payload: {
          name: "gstin",
          value: data?.profile?.gstin,
        },
      });
    } else {
      // somethingWentWrong();
    }
  } catch (e) {}
};

import { getAgencyProfile } from "../../services/api.service";

export const fetchAgencyProfile = (id) => async (dispatch) => {
  try {
    const resp = await getAgencyProfile(id);
    if (resp?.data?.success) {
      let body = {
        name: resp?.data?.data?.name,
        paymentID: resp?.data?.data?.paymentID,
        phone: resp?.data?.data?.phone,
      };
      console.log("BODY", body);
      dispatch({
        type: "SET-SELECT-AGENCY-PROFILE",
        payload: body,
      });
    }
  } catch (e) {
    console.log(e);
  }
};

const initState = {
  agency: "",
  fullName: "",
  email: "",
  organization: "",
  country: "",
  address: "",
  gstin: "",
  panNumber: "",
  loading: true,
};

const ProfileReducer = (state = initState, action) => {
  switch (action.type) {
    case "PROFILE":
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    default:
      return state;
  }
};

export default ProfileReducer;

import { combineReducers, configureStore } from "@reduxjs/toolkit";
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import AuthReducer from "./auth/AuthReducer";
import ProfileReducer from "./profile/ProfileReducer";
import LoginReducer from "./login/LoginReducer";
import ToastReducer from "./toast/ToastRducer";
import AgencyReducer from "./agency/AgencyReducer";

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["toggle"],
};

const reducers = combineReducers({
  Auth: AuthReducer,
  Profile: ProfileReducer,
  Login: LoginReducer,
  Toast: ToastReducer,
  Agency: AgencyReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

let Store = configureStore({
  reducer: persistedReducer,
  devTools: true,
});

export default Store;

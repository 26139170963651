/* eslint-disable no-unused-vars */
import React from "react";
import Header from "../../components/Header/Header";
import Styles from "./CompletedProject.module.scss";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  BILL,
  CANCELLED,
  HOLD,
  NO_COMPLETED_PROJECT,
  STATUS,
  TIMER,
} from "../../asset";

function CompletedProject() {
  const { tasks, profile, isLogIN } = useSelector((state) => state.Auth);
  const navigate = useNavigate();
  const statusIcon = {
    "In Progress": (
      <img src={TIMER} alt="In Progress" style={{ width: 16, height: 16 }} />
    ),
    Completed: (
      <img src={STATUS} alt="Completed" style={{ width: 16, height: 16 }} />
    ),
    Hold: <img src={HOLD} alt="Hold" style={{ width: 16, height: 16 }} />,
    Cancelled: (
      <img src={CANCELLED} alt="Cancelled" style={{ width: 16, height: 16 }} />
    ),
  };

  return (
    <div className={Styles.Completed_Container}>
      <Header title="Completed Project" />
      {profile?.projects?.filter((item) => item?.status === "Completed")
        ?.length === 0 && (
        <div className={Styles.Image}>
          <img src={NO_COMPLETED_PROJECT} alt="No Completed Projects" />
          <h2>No Completed Projects Found !</h2>
          <h3>Your completed projects will appear here</h3>
        </div>
      )}

      <div className={Styles.List_Container}>
        {profile?.projects
          ?.filter((item) => item?.status === "Completed")
          ?.map((item, index) => (
            <button
              className={Styles.Project_Item}
              onClick={() => navigate(`/project/${item?._id}`)}
            >
              <h2>{item?.name}</h2>
              <div className={Styles.Status}>
                <h3>
                  {statusIcon[item?.status]}
                  {item?.status}
                </h3>
              </div>
            </button>
          ))}
      </div>
    </div>
  );
}

export default CompletedProject;

// ICONS
export const BILL = require("./icons/bill.png");
export const CANCELLED = require("./icons/cancelled.png");
export const HOLD = require("./icons/hold.png");
export const STATUS = require("./icons/status.png");
export const TIMER = require("./icons/timer.png");
export const MALE = require("./icons/male.png");
export const FEMALE = require("./icons/female.png");

// IMAGES
export const BG1 = require("./images/bg1.png");
export const BG2 = require("./images/bg2.png");
export const IMAGE1 = require("./images/image-1.png");
export const IMAGE2 = require("./images/image-2.png");
export const IMAGE3 = require("./images/image-3.png");
export const PAYMENT = require("./images/payment.png");
export const PAYMENT2 = require("./images/payment2.png");
export const PROFILE = require("./images/profile.png");
export const NO_AGENCY = require("./images/noAgencies.png");
export const NO_PROJECT = require("./images/noProjects.png");
export const NO_COMPLETED_PROJECT = require("./images/noCompletedProjects.png");

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import {
  BG1,
  BG2,
  BILL,
  CANCELLED,
  FEMALE,
  HOLD,
  MALE,
  NO_PROJECT,
  STATUS,
  TIMER,
} from "../../asset";
import Styles from "./Home.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfileDetails } from "../../redux/auth/AuthAction";
import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // selectors
  let { profile } = useSelector((state) => state.Auth);
  const { email, otp } = useSelector((state) => state.Login);
  const { loading, agency, selectedAgency } = useSelector(
    (state) => state.Profile
  );

  // STATE
  const [notification, setNotification] = useState(false);
  const notificationListener = useRef();
  const responseListener = useRef();
  const statusIcon = {
    "In Progress": (
      <img src={TIMER} alt="In Progress" style={{ width: 16, height: 16 }} />
    ),
    Completed: (
      <img src={STATUS} alt="Completed" style={{ width: 16, height: 16 }} />
    ),
    Hold: <img src={HOLD} alt="Hold" style={{ width: 16, height: 16 }} />,
    Cancelled: (
      <img src={CANCELLED} alt="Cancelled" style={{ width: 16, height: 16 }} />
    ),
  };
  // FETCH PROFILE
  const fetchProfile = () => {
    const formData = {
      email,
      otp,
    };
    dispatch(fetchProfileDetails(formData));
  };
  useEffect(() => {
    fetchProfile();
  }, [email, otp]);
  return (
    <div className={Styles.Home_Container}>
      <div className={Styles.Top}>
        <div className={Styles.Details}>
          <div className={Styles.Left}>
            <h4>Hey, {profile?.name}</h4>
            <h5> Welcome to SynchroWork</h5>
          </div>
          <div className={Styles.Right}>
            <button onClick={() => navigate("/profile")}>
              {profile?.gender === "male" ||
              profile?.gender === null ||
              profile?.gender === undefined ||
              profile?.gender?.trim() === "" ? (
                <img src={MALE} alt="male" style={{ width: 50, height: 50 }} />
              ) : (
                <img
                  src={FEMALE}
                  alt="female"
                  style={{ width: 50, height: 50 }}
                />
              )}
            </button>
          </div>
        </div>
        <img className={Styles.Bg1} src={BG1} alt="bg1" />
        <img className={Styles.Bg2} src={BG2} alt="bg1" />
      </div>
      <div className={Styles.List_Container}>
        <div className={Styles.Count}>
          <p>
            Ongoing Projects (
            {
              profile?.projects?.filter((item) => item?.agency?.name === agency)
                ?.length
            }
            )
          </p>
        </div>
        <h5>
          Showing results from{" "}
          <span style={{ fontWeight: "bold" }}>{selectedAgency}</span>
        </h5>

        {profile?.projects
          ?.filter((item) => item?.agency?.name === agency)
          ?.map((item, index) => (
            <button
              className={Styles.Project_Item}
              onClick={() => navigate(`/project/${item?._id}`)}
            >
              <h2>{item?.name}</h2>
              <div className={Styles.Status}>
                <h3>
                  {statusIcon[item?.status]}
                  {item?.status}, Due on {new Date(item?.dueDate).toDateString()}
                </h3>
              </div>
            </button>
          ))}

        {profile?.projects?.filter((item) => item?.agency?.name === agency)
          ?.length === 0 && (
          <div className={Styles.No_Projects}>
            <img src={NO_PROJECT} alt="No Projects" />
            <h2>No Projects Found !</h2>
            <h3>Your ongoing projects will appear here</h3>
          </div>
        )}
      </div>
      <div className={Styles.Completed_Projects}>
        <button onClick={() => navigate("/completed-projects")}>
          <div className={Styles.Left}>
            <i class="fa-solid fa-cube"></i>
          </div>
          <div className={Styles.Right}>
            <h4>Completed Projects</h4>
            <h5>Check the past completed projects</h5>
          </div>
        </button>
      </div>
    </div>
  );
}

export default Home;

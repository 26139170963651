const initialState = {
  selectedAgencyProfile: {},
};

const AgencyReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET-SELECT-AGENCY-PROFILE":
      console.log("SET-SELECT-AGENCY-PROFILE");
      return {
        ...state,
        selectedAgencyProfile: action.payload,
      };

    default:
      return state;
  }
};

export default AgencyReducer;

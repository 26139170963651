import React from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header/Header";

function Invoice() {
  const { id } = useParams();
  return (
    <>
      <Header title={"Invoice"} />
      <div
        style={{
          display: "block",
          margin: "auto",
          marginTop: "-2rem",
          width: "90%",
          position: "relative",
          zIndex: 12,
          aspectRatio: 9 / 16,
          backgroundColor: "white",
          padding: "1rem",
          borderRadius: "4px",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <iframe
          src={`https://vivid-mynd-agency-laveshgaurav.vercel.app/invoice/${id}`}
          title="Invoice Page"
          style={{
            width: "100%",
            zIndex: 12,
            aspectRatio: 9 / 16,
            border: "none",
            outline: "none",
          }}
        />
      </div>
    </>
  );
}

export default Invoice;

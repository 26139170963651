const initState = {
  isLoggedIn: false,
  token: "",
  profile: {},
  tasks: [],
};

const AuthReducer = (state = initState, action) => {
  switch (action.type) {
    case "LOG-IN":
      return {
        ...state,
        isLoggedIn: true,
      };
    case "LOG-OUT":
      return {
        ...state,
        isLoggedIn: false,
        token: "",
        profile: {},
      };
    case "GET-PROFILE":
      return {
        ...state,
        token: action.payload.token,
        profile: action.payload.profile,
      };
    default:
      return state;
  }
};

export default AuthReducer;
